exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/blog/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-blog-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/portfolio/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-portfolio-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */)
}

